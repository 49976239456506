import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import { DataContext } from "../context/DataContext";
import { AuthContext } from "../context/AuthContext";

import imgP1 from "../assets/image/table-one-profile-image-1.png";
import Avatar from "../assets/image/l1/png/man.png";

const DashboardTalents = () => {
  const [loading, setLoading] = useState(true);
  const { auth } = useContext(AuthContext);
  const { savedTalents, getSavedTalents } = useContext(DataContext);

  const fetchTalents = async (account_id) => {
    await getSavedTalents(account_id);
    setLoading(false);
  };

  useEffect(() => {
    const account_id = localStorage.getItem("account_id");
    fetchTalents(account_id);
  }, []);

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-25 mt-lg-31">
          <div className="container">
            <div className="mb-14">
              <div className="row mb-11 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0">My Talents</h3>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                    <div className="h-px-48">
                      <Link
                        to="/talent-list"
                        className="font-size-3 font-weight-bold text-red-2 text-uppercase"
                      >
                        Find Talent
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="pl-0  border-0 font-size-4 font-weight-normal"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-normal"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-normal"
                        >
                          Skills
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-normal"
                        >
                          Experience Level
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-normal"
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!loading && savedTalents.length < 1 ? (
                        <tr className="border border-color-2">
                          <td colSpan={5} className="pl-6 border-0 py-7 pr-0">
                            <h4 className="font-size-4 mb-0 font-weight-normal text-black-2">
                              No talents found at the moment
                            </h4>
                          </td>
                        </tr>
                      ) : savedTalents.map((talent, index) => (
                        <tr key={index} className="border border-color-2">
                          <th scope="row" className="pl-6 border-0 py-7 pr-0">
                            <Link
                              to="/candidate-profile"
                              state={{ id: talent.account_id }}
                              className="media min-width-px-235 align-items-center"
                            >
                              <div className="circle-36 mr-6">
                                {loading ? (
                                  <Skeleton width={36} height={36} circle />
                                ) : (
                                  <img src={Avatar} alt={`${talent?.firstname} ${talent?.lastname} Devshop profile`} className="w-100" />
                                )}
                              </div>
                              <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">
                                {loading ? (
                                  <Skeleton width={140} height={24} />
                                ) : `${talent?.firstname} ${talent?.lastname}`}
                              </h4>
                            </Link>
                          </th>
                          <td className="py-7 min-width-px-235 pr-0">
                            <h3 className="font-size-4 font-weight-normal text-black-2 mt-2 mb-0">
                              {loading ? (
                                <Skeleton width={200} height={24} />
                              ) : talent?.title}
                            </h3>
                          </td>
                          <td className="py-7 min-width-px-170 pr-0">
                            {loading ? (
                              <Skeleton width={200} height={24} />
                            ) : (
                              <ul className="d-flex list-unstyled mr-n3 flex-wrap">
                                {talent?.skills && talent?.skills.slice(0, 2).map((skill, index) => (
                                  <li key={index}>
                                    <Link
                                      to="/#"
                                      onClick={(e) => e.preventDefault()}
                                      className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
                                    >
                                      {skill}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </td>
                          <td className="py-7 min-width-px-170 pr-0">
                            <h3 className="font-size-4 font-weight-normal text-black-2 mt-2 mb-0">
                              {loading ? (
                                <Skeleton width={200} height={24} />
                              ) : talent?.experience}
                            </h3>
                          </td>
                          <td className="py-7 min-width-px-110 pr-0">
                            {loading ? (
                              <Skeleton width={100} height={24} />
                            ) : (
                              <Link
                                to="/candidate-profile"
                                state={{ id: talent.account_id }}
                                className="btn btn-outline text-uppercase font-size-3 text-green focus-reset"
                              >
                                View Details
                              </Link>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default DashboardTalents;
